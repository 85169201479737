import { HttpClient } from "../HttpClient";
/**
 * * Delete Youtube Channel Information
 */
export const DeleteYoutubeChannelInformation = async (channelId) => {
  try {
    const { data } = await HttpClient.put(`api/YoutubeChannelInformation`, {
      ChannelId: channelId,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
