import { HttpClient } from "../HttpClient";
/**
 * * Post Claims List
 */
export const PostClaimsList = async (claimsList) => {
  try {
    const { data } = await HttpClient.post("api/contents/ClaimsList", {
      ...claimsList,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
