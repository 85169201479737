<template>
  <MDBAlert color="info" static class="px-4 py-3 mb-4" style="font-size: 14.5px;">
    <h6 class="alert-heading">When setting the YouTube information:</h6>
    <ul class="my-auto">
      <li>Choosing the Wrong Channel May Delay Your Claim</li>
      <li>Video must be <span class="text-danger">Public</span></li>
    </ul>
  </MDBAlert>
  <div class="d-flex justify-content-center flex-column">
    <h4 class="mb-3" style="font-size: 19px;">YouTube Channel</h4>
    <MDBListGroup class="mb-4" style="width: max-content; max-width: 100%; max-height: 30vh; overflow: auto;">
      <MDBListGroupItem v-for="channel in youtubeChannelList" :key="channel.id"
        class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
          <MDBRadio :id="channel.channelId" name="youtube-channel" v-model="selectedYoutubeChannelId"
            :value="channel.channelId" />
          <img :src="channel.thumbnail" alt="thumbnail" style="width: 45px; height: 45px" class="rounded-circle" />
          <label :for="channel.channelId" style="cursor: pointer;font-size: 14px;">{{ channel.name }}</label>
        </div>
        <MDBIcon icon="trash" class="fas fa-fw py-1 px-0 ms-5" style="cursor: pointer;"
          @click="toggleDeleteModal(channel.channelId)" />
      </MDBListGroupItem>
      <MDBListGroupItem class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center" style="gap: 10px;cursor: pointer;">
          <MDBRadio id="GetFromVideo" name="youtube-channel" v-model="selectedYoutubeChannelId" value="GetFromVideo" />
          <img src="@/assets/user-default.png" alt="thumbnail" style="width: 45px; height: 45px"
            class="rounded-circle" />
          <label for="GetFromVideo" style="cursor: pointer;">Get from Video</label>
        </div>
      </MDBListGroupItem>
    </MDBListGroup>
    <h4 style="font-size: 19px;">YouTube Video</h4>
    <div class="input-group w-100 mt-2">
      <MDBBtn color="primary" size="sm">
        <i class="fab fa-youtube fa-fw pb-1"></i>
      </MDBBtn>
      <input v-model="inputVideoUrl" @input="updateYTUrlValue" type="text" class="form-control"
        placeholder="YouTube URL" @keyup.enter="submit" />
      <MDBBtn class="btn btn-primary" type="button" @click="submit">
        Preview
      </MDBBtn>
    </div>
  </div>
  <template v-if="youtubeVideo.title != ''">
    <div class="d-flex justify-content-center align-items-center" v-if="loading">
      <MDBSpinner color="primary" />
    </div>
    <div class="d-flex flex-column justify-content-center overflow-auto" v-else>
      <h5 class="text-center w-100 mt-4">Preview:</h5>
      <img :src="youtubeVideo.thumbnail" class="w-50 d-flex mx-auto mt-3" alt="YouTube Thumbnail" />
      <div class="d-flex gap-2 mt-4 justify-content-center">
        <div
          style="width: 50px; height: 50px; border-radius: 50%; overflow: hidden; display: flex; align-items: center; justify-content: center;">
          <img :src="youtubeVideo.thumbnail" alt="YT Video Thumbnail" style="width: auto; height: 100%;" />
        </div>
        <div class="my-auto video-details">
          <p class="my-auto fw-bold text-left">{{ youtubeVideo.title }}</p>
          <p class="my-auto text-left" style="font-size: 13px;">{{ youtubeVideo.channelName }}</p>
          <p class="my-auto text-left" style="font-size: 13px;">{{ youtubeVideo.viewCount }} Views</p>
        </div>
      </div>
    </div>
  </template>
  <!-- delete modal -->
  <MDBModal id="deleteModal" tabindex="-1" labelledby="deleteModalLabel" v-model="deleteModal">
    <MDBModalHeader>
      <MDBModalTitle id="deleteModalLabel"> Delete Channel </MDBModalTitle>
    </MDBModalHeader>
    <MDBModalBody>Are you sure you want to delete this channel?</MDBModalBody>
    <MDBModalFooter class="gap-2">
      <MDBBtn class="btn" size="sm" @click="deleteModal = false">Close</MDBBtn>
      <MDBBtn class="btn btn-primary" size="sm" @click="confirmDeletion()">Confirm Delete</MDBBtn>
    </MDBModalFooter>
  </MDBModal>
  <!-- toast -->
  <MDBToast v-model="errorToast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
    color="danger" text="white" icon="fas fa-check fa-lg me-2">
    <template #title> Error </template>
    {{ errorToastMessage }}
  </MDBToast>
</template>

<script setup>
import { MDBBtn, MDBSpinner, MDBToast, MDBAlert, MDBRadio, MDBListGroup, MDBListGroupItem, MDBIcon, MDBModal, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter } from "mdb-vue-ui-kit";
import { ref, defineEmits, /* watch, */ onMounted } from "vue";
import { storeToRefs } from "pinia";
import { isYouTubeUrl } from "@/helpers/youtubeScraper";
import { useYoutubeStore } from "@/store/youtubeStore";
import { useUserDetailStore } from "@/store/userDetailStore";
import { GetYoutubeChannelInformation } from "@/services/YoutubeChannelInformation/GetYoutubeChannelInformation";
import { DeleteYoutubeChannelInformation } from "@/services/YoutubeChannelInformation/DeleteYoutubeChannelInformation";

const youtubeStore = useYoutubeStore();
const { youtubeVideo, youtubeChannelList, selectedYoutubeChannelId } = storeToRefs(youtubeStore);

const emit = defineEmits(['update:youtubeURL']);
const inputVideoUrl = ref(youtubeVideo.value.url);

const updateYTUrlValue = () => {
  emit('update:youtubeURL', inputVideoUrl.value);
};


const loading = ref(false);
const errorToast = ref(false);
const errorToastMessage = ref("Error");

const submit = async () => {
  if (inputVideoUrl.value === "") {
    errorToastMessage.value = "Please enter a YouTube URL to preview.";
    errorToast.value = true;
    return;
  }
  if (!isYouTubeUrl(inputVideoUrl.value)) {
    errorToastMessage.value = "Please enter a valid YouTube URL.";
    errorToast.value = true;
    return;
  }
  loading.value = true;
  // https://www.youtube.com/watch?v=KkCXLABwHP0
  // https://www.youtube.com/watch?v=eRzljx9nDdY
  youtubeStore.setYoutubeVideo(inputVideoUrl.value)
  loading.value = false;
};

const userDetailStore = useUserDetailStore();
const { userDetail } = storeToRefs(userDetailStore);


const getYoutubeChannelInformationList = async (userId) => {
  try {
    const response = await GetYoutubeChannelInformation(userId);
    if (response.data.length > 0) {
      youtubeChannelList.value = response.data;
      selectedYoutubeChannelId.value = response.data[0].channelId;
    } else {
      selectedYoutubeChannelId.value = 'GetFromVideo';
    }
  } catch (error) {
    console.error("Error fetching YouTube channel information:", error);
  }
};

const deleteModal = ref(false);
const channel = ref("");

const toggleDeleteModal = (channelId) => {
  deleteModal.value = true;
  channel.value = channelId;
};

const confirmDeletion = () => {
  deleteChannel(channel.value);
};

const deleteChannel = async (channelId) => {
  await DeleteYoutubeChannelInformation(channelId);
  getYoutubeChannelInformationList(userDetail.value.id);
  deleteModal.value = false;
};

onMounted(() => {
  getYoutubeChannelInformationList(userDetail.value.id);
});
</script>

<style scoped>
.btn-primary {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  gap: 5px;
}

.video-details {
  width: 40%;
}

@media screen and (max-width: 767px) {
  .video-details {
    width: 60%;
  }
}
</style>
