import { HttpClient } from "../HttpClient";
/**
 * * Post Youtube Channel Information
 */
export const PostYoutubeChannelInformation = async (channelInfo) => {
  try {
    const { data } = await HttpClient.post(`api/YoutubeChannelInformation`, {
      ...channelInfo,
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
