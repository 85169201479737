<template>
    <div class="px-2">
        <div class="video-container mb-4">
            <iframe width="600" height="360" class="responsive-iframe" :src="youtubeVideoIframe" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>
        <h5 class="mb-4" style="font-size: 18px;">Input what clip(s) you used.</h5>
        <div class="overflow-auto">
            <table class="table">
                <thead class="sticky-top bg-white">
                    <tr>
                        <th scope="col" class="text-nowrap px-3">Clip #</th>
                        <th scope="col" class="text-nowrap px-3">Clip Title</th>
                        <th scope="col" class="text-nowrap px-3">Start Time</th>
                        <th scope="col" class="text-nowrap px-3">End Time</th>
                        <th scope="col" class="text-nowrap px-3">Clip URL</th>
                        <th scope="col" class="text-nowrap px-3"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(clip, index) in clipList" :key="index">
                        <th scope="row" class="align-middle text-center p-3">{{ index + 1 }}</th>
                        <td class="p-3">
                            <input v-model="clip.clipTitle" @input="updateClipsValue()" type="text"
                                :class="clip.class.clipTitle" id="clipTitle" autocomplete="off"
                                placeholder="Enter Clip Title" />
                            <p :class="clip.errorMessage.clipTitle.class"
                                style="font-size: 11px; margin-top: 5px; margin-bottom: 0;">
                                {{ clip.errorMessage.clipTitle.displayText }}
                            </p>
                        </td>
                        <td class="p-3">
                            <input type="text" :class="clip.class.startTime" v-model="clip.startTime"
                                @input="updateClipsValue()" autocomplete="off" :placeholder="placeholder" />
                            <p :class="clip.errorMessage.startTime.class"
                                style="font-size: 11px; margin-top: 5px; margin-bottom: 0;">
                                {{ clip.errorMessage.startTime.displayText }}
                            </p>
                        </td>
                        <td class="p-3">
                            <input type="text" :class="clip.class.endTime" v-model="clip.endTime"
                                @input="updateClipsValue()" autocomplete="off" :placeholder="placeholder" />
                            <p :class="clip.errorMessage.endTime.class"
                                style="font-size: 11px; margin-top: 5px; margin-bottom: 0;">
                                {{ clip.errorMessage.endTime.displayText }}
                            </p>
                        </td>
                        <td class="p-3">
                            <input v-model="clip.clipURL" @input="updateClipsValue()" type="text"
                                :class="clip.class.clipURL" id="clipLink" autocomplete="off"
                                placeholder="Enter Clip URL" />
                            <p :class="clip.errorMessage.clipURL.class"
                                style="font-size: 11px; margin-top: 5px; margin-bottom: 0;">
                                {{ clip.errorMessage.clipURL.displayText }}
                            </p>
                        </td>
                        <td class="p-3">
                            <div class="delete-button d-flex justify-content-end align-items-start gap-2"
                                style="padding-top: 2px;">
                                <MDBBtn color="primary" size="sm" @click="removeClip(index)"
                                    :disabled="clipList.length === 1">
                                    <MDBIcon icon="trash" class="fas fa-fw py-1" />
                                </MDBBtn>
                                <MDBBtn color="primary" size="sm" @click="duplicateClip(index)">
                                    <MDBIcon icon="clone" class="fas fa-fw py-1" />
                                </MDBBtn>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, computed } from "vue";
import { MDBBtn, MDBIcon } from "mdb-vue-ui-kit";
import { storeToRefs } from "pinia";
import { useYoutubeStore } from "@/store/youtubeStore";

const youtubeStore = useYoutubeStore();
const { youtubeVideo, clipList, youtubeVideoDuration } = storeToRefs(youtubeStore);

const youtubeVideoIframe = computed(() => `https://www.youtube.com/embed/${youtubeVideo.value.id}?rel=0&color=white&disablekb=1&modestbranding=1&cc_load_policy=1`);

const emit = defineEmits(['update:clips']);

const updateClipsValue = () => {
    emit('update:clips', clipList.value);
};

const removeClip = (index) => {
    emit('deleteClip', index);
};

const duplicateClip = (index) => {
    emit('copyClip', index);
};
const placeholder = computed(() => {
    return youtubeStore.hasHour(youtubeVideoDuration.value) ? 'HH:MM:SS' : 'MM:SS';
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.clips-container {
    display: flex;
    width: fit-content;
}

.time-input {
    width: fit-content;
}

.clip-time {
    display: flex;
}

.clipTitleLink {
    width: 45%;
}

.delete-button {
    width: fit-content;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.table-container {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.video-container {
    display: flex;
    justify-content: center;
}

.responsive-iframe {
    margin: auto 0;
}

@media screen and (max-width: 767px) {
    .video-container {
        width: 100%;
        height: 25vh;
        display: flex;
        justify-content: center;
    }

    .responsive-iframe {
        width: 100%;
        height: 100%;
        margin: auto 0;
    }

    .clips-container {
        display: block;
    }

    .time-input {
        width: 100%;
    }

    .clip-time {
        display: flex;
    }

    .clipTitleLink {
        width: 100%;
    }

    .delete-button {
        width: 100%;
    }
}

@media screen and (max-width: 515px) {
    .clip-time {
        display: block;
    }
}
</style>
